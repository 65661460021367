import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface enableDivideParam {
    taskId?:any
}

export interface updateTaskParam {
    image?:any
    rewardNumber?:any
    kind?:any
    rewardType?:any
    showAlways?:any
    sort?:any
    completeCount?:any
    type?:any
    extensions?:any
    appId?:any
    hint?:any
    name?:any
    id?:any
}

export interface removeTaskParam {
    taskId?:any
}

export interface saveTaskParam {
    image?:any
    extensions?:any
    rewardNumber?:any
    kind?:any
    appId?:any
    hint?:any
    rewardType?:any
    name?:any
    showAlways?:any
    sort?:any
    completeCount?:any
    type?:any
}

export interface loadUserTasksByTypeParam {
    type?:any
}

export interface changeStatusParam {
    taskId?:any
}

export interface loadPageParam {
    current?:any
    size?:any
    enable?:any
    kind?:any
    appId?:any
    keyword?:any
    type?:any
}

export interface loadTasksExtensionsParam {
    taskId?:any
}
// -------接口列------

/**
 * [后台]编辑-更新任务
 * @notes 
 */
export function removeTask(param:removeTaskParam) {
    return get('/task/tasks/removeTask', param);
}

/**
 * [APP]加载用户任务数据
 * @notes 
 */
export function loadUserTasks() {
    return get('/task/tasks/loadUserTasks');
}

/**
 * [后台]是否可用于分佣
 * @notes 
 */
export function enableDivide(param:enableDivideParam) {
    return get('/task/tasks/enableDivide', param);
}

/**
 * [后台]编辑-更新任务
 * @notes 
 */
export function updateTask(param:updateTaskParam) {
    return post('/task/tasks/updateTask', param);
}

/**
 * [后台]保存-新增任务
 * @notes 
 */
export function saveTask(param:saveTaskParam) {
    return post('/task/tasks/saveTask', param);
}

/**
 * [后台]加载任务时间配置
 * @notes 
 */
export function loadTasksExtensions(param:loadTasksExtensionsParam) {
    return get('/task/tasks/loadTasksExtensions', param);
}

/**
 * [后台]改变状态
 * @notes 
 */
export function changeStatus(param:changeStatusParam) {
    return get('/task/tasks/changeStatus', param);
}

/**
 * [后台]加载任务类型
 * @notes 
 */
export function loadTasksTypes() {
    return get('/task/tasks/loadTasksTypes');
}

/**
 * [后台]加载任务数据
 * @notes 
 */
export function loadPage(param:loadPageParam) {
    return get('/task/tasks/loadPage', param);
}

/**
 * [APP]加载用户某一类任务数据（如看视频页任务）
 * @notes 
 */
export function loadUserTasksByType(param:loadUserTasksByTypeParam) {
    return get('/task/tasks/loadUserTasksByType', param);
}

