
  import { loadEnabled } from '@/api/system/appConfig';
  import { changeStatus, enableDivide, loadPage, loadTasksTypes, removeTask } from '@/api/task/tasks';
  import JtPagination from '@/components/JtPagination/index.vue';
  import { Component,Vue, Watch } from 'vue-property-decorator'
  import TaskEditOrAdd from './components/TaskEditOrAdd.vue';
    
  
  @Component({
      name: 'TasksList',
      components: {
        JtPagination,
        TaskEditOrAdd
      }
  })
  export default class extends Vue {
    isEdit = false
    dialogUpdateTaskVisible = false
    listLoading = true
    total = 0
    list :any = []
    appconfigs :any = []
    taskTypes :any = []
    listQuery = {
        appId: null,
        type: null,
        enable: null,
        kind: null,
        keyword: null,
        current: 1,
        size: 10
    }
    updateRow = {}
  
    mounted() {
        this.getList()
        this.getTaskTypes()
        this.getAppconfigs()
    }
    //初始加载数据
    handleFilter() {
      this.listQuery.current = 1
      this.getList()
    }
    
    //加载应用信息
    getAppconfigs(){
        loadEnabled().then(res => {
            this.appconfigs = res.data
        })
    }
    
    //加载任务类型信息
    getTaskTypes(){
        loadTasksTypes().then(res => {
            this.taskTypes = res.data
        })
    }

    //加载api数据
    getList(){
      this.listLoading = true
      loadPage(this.listQuery).then(response => {
          this.list = response.data.rows
          this.total = response.data.total
          // Just to simulate the time of the request
          setTimeout(() => {
            this.listLoading = false
          }, 500)
        }).catch(()=>{
          this.listLoading = false
        })
    }
  
    handleModifyStatus(row:any){
      this.$confirm('此操作将【' + (row.enable ? '冻结' : '恢复') + '】当前账户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeStatus({ taskId: row.id }).then(response => {
          this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.getList()
        }).catch(()=>{})
      }).catch(() => {
        this.$message({
              type: 'info',
              message: '已取消当前操作'
            });      
      });
    }
  
    handleModifyDivide(row:any){
      this.$confirm('是否【' + (row.divide ? '关闭' : '开启') + '】上下级分佣?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        enableDivide({ taskId: row.id }).then(response => {
          this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.getList()
        }).catch(()=>{})
      }).catch(() => {
        this.$message({
              type: 'info',
              message: '已取消当前操作'
            });      
      });
    }

    resetSearch(){
      this.listQuery.appId = null
      this.listQuery.enable = null
      this.listQuery.type = null
      this.listQuery.keyword = null
      this.listQuery.kind = null
    }

    handleUpdate(row:any){
      this.dialogUpdateTaskVisible = true
      this.isEdit = true
      this.updateRow = row
    }
    
    handleAddTask(){
      this.isEdit = false
      this.dialogUpdateTaskVisible = true
      this.updateRow = {}
    }

    handleDelete(row:any){
      this.$confirm('此操作将删除当前信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeTask({ taskId: row.id }).then(response => {
          this.handleFilter()
          this.$message({
              type: 'success',
              message: '操作成功!'
            });
        }).catch(()=>{})
      }).catch(() => {
        this.$message({
              type: 'info',
              message: '已取消当前操作'
        });      
      });
    }
  }
  