
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'MultipleTimes'
})
export default class MultipleTimes extends Vue {
    @Prop({ required: true, default: () => [] }) readonly selectTimes!: Array<{
        taskId:number
        startTime:string
        endTime:string
        completeCount:any
    }>;

    get dataSetsInfo(){
        return this.selectTimes;
    }

    set dataSetsInfo(val: any){
      this.$emit('update:selectTimes', val)
    }

    deleteRow(index:number){
        this.dataSetsInfo.splice(index,1);
    }

    addRow(){
        this.dataSetsInfo.push({ startTime: "", endTime: "", completeCount: null });
    }

    mounted(){
        this.checkFirst()
    }

    @Watch("selectTimes")
    checkFirst(){
        if(this.dataSetsInfo.length === 0){
            this.addRow()
        }
    }
}
