
import {loadTasksExtensions, loadTasksTypes, saveTask, updateTask} from '@/api/task/tasks';
import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'
import MultipleTimes from './MultipleTimes.vue';
import {loadEnabled} from '@/api/system/appConfig';
import JtAvatarUpload from '@/components/JtAvatarUpload/index.vue';

@Component({
  name: 'TaskEditOrAdd',
  components: {
    MultipleTimes,
    JtAvatarUpload
  }
})
export default class TaskEditOrAdd extends Vue {
  @Prop({required: true, default: false}) readonly dialogEditVisible!: boolean;
  @Prop({required: true, default: {}}) readonly formData!: any;
  @Prop({required: true, default: false}) readonly isEdit!: boolean;

  formParam = {
    id: null,
    name: null,
    hint: null,
    appId: null,
    image: "",
    rewardType: null,
    rewardNumber: null,
    completeCount: null,
    type: null,
    kind: null,
    sort: null,
    routeId: null,
    showAlways: false,
    extensions: []
  }

  taskTypes: Array<{
    code?: string
    desc?: string
  }> = []

  appconfigs: any = []

  get dialogVisible() {
    return this.dialogEditVisible;
  }

  set dialogVisible(val) {
    this.$emit('update:dialogEditVisible', val)
  }

  get infoTitle() {
    if (this.isEdit) {
      return "编辑任务-【" + this.formParam.name + "】";
    } else {
      return "添加任务";
    }
  }


  @Emit("getList")
  getList() {
  }

  handleImageSuccess(res: any, file: any) {
    this.formParam.image = URL.createObjectURL(file.raw);
  }

  handleUpdateTask() {
    if (this.isEdit) {
      updateTask(this.formParam).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.dialogVisible = false
        this.getList();
      })
    } else {
      saveTask(this.formParam).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.dialogVisible = false
        this.getList();
      })
    }
  }

  mounted() {
    this.loadTasksTypes()
    this.getAppconfigs()
  }

  loadTasksTypes() {
    loadTasksTypes().then(res => {
      this.taskTypes = res.data
    })
  }

  @Watch("formData")
  initParam() {
    if (this.isEdit) {
      this.formParam = this.formData
      loadTasksExtensions({taskId: this.formParam.id}).then(res => {
        this.formParam.extensions = res.data
      })
    } else {
      this.formParam = {
        id: null,
        appId: null,
        name: null,
        hint: null,
        image: "",
        rewardType: null,
        rewardNumber: null,
        completeCount: null,
        type: null,
        kind: null,
        sort: null,
        routeId: null,
        showAlways: false,
        extensions: []
      }
    }
  }

  //加载应用信息
  getAppconfigs() {
    loadEnabled().then(res => {
      this.appconfigs = res.data
    })
  }
}
